import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { WithSkeleton } from '@shared/ui/progress-loaders/Skeleton';
const InfoContainerInner = styled.div(() => ({
    display: 'flex',
    flexDirection: 'column',
    width: '33%',
    gap: '5px',
}));
const InfoVariable = styled.span((props) => ({
    fontSize: '12px',
    fontWeight: 400,
    color: props.theme.palette.text.secondary,
    cursor: 'default',
    userSelect: 'none',
}));
const InfoValue = styled(InfoVariable)(props => ({
    fontSize: '14px',
    fontWeight: 500,
    color: props.valueColor ? props.valueColor : '#080A0B',
}));
const InfoContainer = ({ variable = '', value = '', valueColor = undefined, isLoading = false, }) => (_jsxs(InfoContainerInner, { children: [_jsx(WithSkeleton, { isLoading: isLoading, width: 90, height: 12, children: _jsx(InfoVariable, { children: variable }) }), _jsx(WithSkeleton, { isLoading: isLoading, width: 50, height: 19, children: _jsx(InfoValue, { valueColor: valueColor, children: value }) })] }));
export default InfoContainer;
