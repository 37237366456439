import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { ReactComponent as DrawdownMock } from './drawdown-mock.svg';
import { ReactComponent as DrawdownMockEmpty } from './drawdown-mock-empty.svg';
const DrawdownMockEmptyStyled = styled(DrawdownMockEmpty, { shouldForwardProp: propName => propName !== 'isLoading' })(props => ({
    path: {
        fill: props.isLoading ? props.theme.palette.primary.light : undefined,
    },
    rect: {
        fill: props.isLoading ? props.theme.palette.primary.light : undefined
    }
}));
const DrawdownGraph = ({ isEmpty = false, isLoading = false, }) => {
    if (isEmpty)
        return _jsx(DrawdownMockEmptyStyled, { isLoading: isLoading, width: 376, height: 66 });
    return _jsx(DrawdownMock, { width: 376, height: 66 });
};
export default DrawdownGraph;
