const defineDrawdownRisk = (drawdown) => {
    if (!drawdown)
        return '';
    if (drawdown <= -1 && drawdown > -15)
        return 'low';
    if (drawdown <= -15 && drawdown > -50)
        return 'average';
    if (drawdown <= -50)
        return 'high';
    return '';
};
export default defineDrawdownRisk;
