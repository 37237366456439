import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { WithSkeleton } from '@shared/ui/progress-loaders/Skeleton';
const SectionTitleComponent = styled.div(() => ({
    display: 'flex',
    height: 24,
    justifyContent: 'space-between',
}));
const SectionTitleText = styled.span(() => ({
    flex: '0 0 auto',
    letterSpacing: '-0.096px',
    lineHeight: '24px',
}));
const SectionTitleFiller = styled.span(() => ({
    display: 'flex',
    flex: '1 1 auto',
    height: '1px',
    margin: '0px 2px 4px',
    alignSelf: 'end',
    backgroundImage: 'linear-gradient(to right, #7E868C 10%, rgba(255, 255, 255, 0) 0%)',
    backgroundPosition: 'top',
    backgroundSize: '8px 2px',
    backgroundRepeat: 'repeat-x',
}));
const SectionTitleValue = styled.span(() => ({
    flex: '0 0 auto',
    alignSelf: 'start',
    lineHeight: '24px',
}));
const SectionTitle = ({ title = null, value = null, isLoading = false, }) => (_jsxs(SectionTitleComponent, { children: [_jsx(WithSkeleton, { isLoading: isLoading, width: 90, height: 18, style: {
                alignSelf: 'end',
            }, children: _jsx(SectionTitleText, { children: title }) }), !isLoading &&
            _jsx(SectionTitleFiller, {}), _jsx(WithSkeleton, { isLoading: isLoading, width: 50, height: 24, children: _jsx(SectionTitleValue, { children: value }) })] }));
export default SectionTitle;
